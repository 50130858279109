import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  cellRendererIndexMethod,
  getHeadersDefaultIndexMethodCalculation,
  getNestedChildrenIdsIndexMethodCalculation,
  getRowClassIndexMethodCalculation,
  IIndexMethodCalculationContext,
  IndexMethodCalculationTableProps
} from '.';
import { WrapperAgGrid } from 'pages/Calculations/components/Accomplishment/Accomplishment.styles';
import { AgGridReact } from 'ag-grid-react';
import Progress from 'components/Progress';
import {
  ComplicatedDynamicRow,
  IIndexMethodTable
} from 'types/index-method/index-method';
import { ColDef, ColGroupDef, IRowNode } from 'ag-grid-community';
import { useGetCalculationCalcIDComplicatedQuery } from 'api/calculations';
import { ENUMLocalStorage, useLocalStorage } from 'hooks/use-local-storage';

const defaultColDef = { suppressMovable: true, resizable: false };

export const IndexMethodCalculation: FC<IndexMethodCalculationTableProps> = (
  props
) => {
  const ref = useRef<AgGridReact<IIndexMethodTable> | null>(null);
  const levelID = useMemo(
    () => Number(props.calcID || props.fileID || 0),
    [props.calcID, props.fileID]
  );
  const { setValue: setLevelsNotPreview, firstValue: firstLevelsNotPreview } =
    useLocalStorage(ENUMLocalStorage.levelsIndexMethod, []);
  const { setValue: setLevelsPreview, firstValue: firstLevelsPreview } =
    useLocalStorage(ENUMLocalStorage.levelsIndexMethodPreview, []);

  const setLevels = useMemo(
    () => (props.mode === 'editor' ? setLevelsNotPreview : setLevelsPreview),
    [props.mode, setLevelsNotPreview, setLevelsPreview]
  );
  const firstLevels = useMemo(
    () =>
      props.mode === 'editor' ? firstLevelsNotPreview : firstLevelsPreview,
    [firstLevelsNotPreview, firstLevelsPreview, props.mode]
  );

  const {
    data: dataCalculationComplicated,
    isFetching: isFetchingCalculationComplicated,
    isLoading: isLoadingCalculationComplicated
  } = useGetCalculationCalcIDComplicatedQuery(
    props.mode === 'editor'
      ? {
          calcID: props.calcID,
          type: 'rim',
          isPreview: false
        }
      : {
          type: 'rim',
          isPreview: true,
          fileID: props.fileID,
          projectID: props.projectID
        }
  );

  const isLoading = useMemo(
    () => isFetchingCalculationComplicated || isLoadingCalculationComplicated,
    [isFetchingCalculationComplicated, isLoadingCalculationComplicated]
  );

  const data: IIndexMethodTable[] = useMemo(
    () =>
      dataCalculationComplicated?.data.map((e) => ({
        ...e
      })) ?? [],
    [dataCalculationComplicated?.data]
  );

  const defaultData: IIndexMethodTable[] = useMemo(() => {
    return (
      dataCalculationComplicated?.data.map((e) => ({
        ...e
      })) ?? []
    );
  }, [dataCalculationComplicated?.data]);

  const total = useMemo(() => {
    return dataCalculationComplicated?.total
      ? [dataCalculationComplicated?.total]
      : [];
  }, [dataCalculationComplicated?.total]);

  const totalDynamicRows: ComplicatedDynamicRow[] = useMemo(() => {
    return total ? (total[0]?.dynamicRows ?? []) : [];
  }, [total]);

  const [columnDefs, setColumnDefs] = useState<
    (ColDef<IIndexMethodTable> | ColGroupDef<IIndexMethodTable>)[] | undefined
  >();

  const pinnedTopRowData = useMemo(
    () => (columnDefs?.length ? total : undefined),
    [columnDefs?.length, total]
  );
  const rowData = useMemo(
    () => (columnDefs?.length ? data : undefined),
    [columnDefs?.length, data]
  );

  const [collapseRowsIds, setCollapseRowsIds] = useState<number[]>([]);

  const onCollapse = useCallback(
    (ids: number[]) => {
      setCollapseRowsIds((prevState) => {
        const idsToProcess = ids.flatMap((id) => {
          const nestedIds = getNestedChildrenIdsIndexMethodCalculation(
            id,
            defaultData
          );
          return [id, ...nestedIds];
        });

        const isCollapsing = ids.some((id) => prevState.includes(id));

        if (isCollapsing) {
          // Если элемент уже есть в списке, его дети не исчезают из списка
          return prevState.filter((hrId) => !ids.includes(hrId));
        } else {
          // Если элемент не в списке, добавляем его и его детей в список
          return [...prevState, ...idsToProcess];
        }
      });
    },
    [defaultData]
  );

  const doesExternalFilterPass = useCallback(
    (params: IRowNode<IIndexMethodTable>) => {
      ref.current?.api.setIsExternalFilterPresent(() => false);
      const shouldClose = params.data?.parentID
        ? !collapseRowsIds.includes(params.data?.parentID)
        : true;
      return shouldClose;
    },
    [collapseRowsIds]
  );

  const contextTable = useMemo<IIndexMethodCalculationContext>(
    () => ({
      collapseRowsIds,
      onCollapse
    }),
    [collapseRowsIds, onCollapse]
  );

  useEffect(() => {
    if (data.length) {
      setColumnDefs([
        ...getHeadersDefaultIndexMethodCalculation({
          totalDynamicRows: totalDynamicRows
        })
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length, totalDynamicRows.length]);

  useEffect(() => {
    if (ref.current && ref.current.api) {
      if (isLoading) {
        ref.current.api.showLoadingOverlay();
      } else {
        ref.current.api.hideOverlay();
      }
    }
  }, [isLoading, contextTable]);

  useEffect(() => {
    if (ref.current && ref.current.api) {
      ref.current.api.refreshCells({ force: true, suppressFlash: true });
      ref.current.api.refreshHeader();
    }
  }, [data]);

  useEffect(() => {
    if (ref.current && ref.current.api) {
      ref.current.api.setIsExternalFilterPresent(() => true);
      ref.current.api.onFilterChanged();
      ref.current.api.refreshCells({ force: true, suppressFlash: true });
    }
  }, [doesExternalFilterPass]);

  useEffect(() => {
    if (collapseRowsIds) {
      setLevels((prevState) => {
        const isFind = prevState?.find((level) => level.id === levelID);
        if (isFind) {
          return prevState?.map((level) => {
            if (level.id === levelID) {
              return {
                id: levelID,
                levels: collapseRowsIds
              };
            }
            return level;
          });
        } else {
          return [
            ...(prevState ?? []),
            {
              id: levelID,
              levels: collapseRowsIds
            }
          ];
        }
      });
    }
  }, [collapseRowsIds, levelID, setLevels]);

  useEffect(() => {
    const isFind = (firstLevels ?? []).find((level) => level.id === levelID);
    if (isFind) {
      setCollapseRowsIds(isFind.levels);
    } else {
      if (data && data.length) {
        const firstElements = data
          .map((item) => (item.parentID === null ? item.id : null))
          .filter((item): item is number => item !== null);
        if (firstElements && firstElements.length) {
          onCollapse(firstElements);
        }
      }
    }
  }, [data, firstLevels, levelID, onCollapse, props.calcID, props.fileID]);

  return (
    <WrapperAgGrid className="ag-theme-material reference-prices">
      <AgGridReact<IIndexMethodTable>
        ref={ref}
        context={contextTable}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        getRowClass={getRowClassIndexMethodCalculation}
        gridOptions={{
          components: {
            cellRenderer: cellRendererIndexMethod
          }
        }}
        pinnedTopRowData={pinnedTopRowData}
        rowData={rowData}
        getRowId={(params) => (params.data.id || '').toString()}
        getRowHeight={(params) => {
          if (params.node.rowPinned === 'top') {
            return 50;
          }
          return 55;
        }}
        rowStyle={{
          padding: '0 !important'
        }}
        suppressCellFocus
        enableCellTextSelection
        ensureDomOrder
        maintainColumnOrder
        rowHeight={55}
        headerHeight={43}
        doesExternalFilterPass={doesExternalFilterPass}
        loadingOverlayComponent={Progress}
        noRowsOverlayComponent={Progress}
        onFirstDataRendered={(event) => {
          event.api.sizeColumnsToFit();
        }}
        onGridSizeChanged={(event) => {
          event.api.sizeColumnsToFit();
        }}
        onViewportChanged={(event) => {
          event.api.sizeColumnsToFit();
        }}
      />
    </WrapperAgGrid>
  );
};
