import { RowClassParams } from 'ag-grid-community';
import { IIndexMethodTable } from 'types/index-method/index-method';

export function getRowClassIndexMethodCalculation(
  params: RowClassParams<IIndexMethodTable>
) {
  return params.data?.type === 'file'
    ? `level-${params.data?.lvl}`
    : params.data?.type === 'level'
      ? 'rate'
      : 'rate-position';
}

export const getNestedChildrenIdsIndexMethodCalculation = (
  id: number,
  defaultData: IIndexMethodTable[]
): number[] => {
  const children = defaultData.filter((e) => e?.parentID === id);

  return children.reduce<number[]>(
    (acc, child) =>
      [
        ...acc,
        child.id,
        ...getNestedChildrenIdsIndexMethodCalculation(child.id, defaultData)
      ].filter((e) => e !== undefined),
    []
  );
};
