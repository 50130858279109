import { FC, useCallback, useState } from 'react';
import {
  IndexMethodHeaderProps,
  IndexMethodSearchParamsTab,
  INDEX_METHOD_TAB_VALUES
} from '.';
import { Box, Button, Menu, MenuItem, Stack, Tab, Tabs } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CopyDrawer from '../../Calculations/components/CalculationDirectory/components/CopyDrawer';
import { DrawerEdit } from 'pages/Calculations/components/EditCalculation';
import { useDeleteCalculationMutation } from 'api/calculations';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { deleteData, openDB } from 'utils/indexDB';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useTranslation } from 'react-i18next';
import { ParametersDialog } from 'pages/Calculations/components/CalculationBasic/components/ParametersDialog';

const getKeyByIndex = (
  index: number
): IndexMethodSearchParamsTab | undefined => {
  const keys = Object.keys(INDEX_METHOD_TAB_VALUES);
  return keys[index] as IndexMethodSearchParamsTab;
};

export const IndexMethodHeader: FC<IndexMethodHeaderProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation('user');
  const { enqueueSnackbar } = useSnackbar();

  // * Tab * //
  const handleSelectedTab = (
    _event: React.SyntheticEvent,
    newValue: number
  ) => {
    const newTab = getKeyByIndex(newValue);
    if (newTab) props.setTab(newTab);
  };

  const tabIndex = Object.keys(INDEX_METHOD_TAB_VALUES).indexOf(props.tab);

  // * Edit Calculation * //
  const [editCalc, setEditCalc] = useState(false);
  const handleOpenEditCalc = () => {
    setEditCalc(true);
  };

  // * Copy Calculation * //
  const [copyDrawer, setCopyDrawer] = useState(false);
  const toggleOpenCopy = useCallback(
    (copy?: boolean) => setCopyDrawer((prevState) => (copy ? copy : prevState)),
    []
  );

  // * Menu * //
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenuCalculation = Boolean(anchorEl);
  const handleCloseMenuCalculation = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleOpenMenuCalculation = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => setAnchorEl(event.currentTarget);

  // * Delete Calculation * //
  const isDisabledDeleteCalculation = false;
  const isExecution = props.tab === 'execution';

  const [deleteCalculation, deleteCalculationResponse] =
    useDeleteCalculationMutation();

  const handleConfirmDelete = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        deleteCalculation({
          projectID: props.projectID,
          calcID: props.calcID
        }).then(() => {
          openDB().then((db) => deleteData(db, props.calcID));
        });
      }
    },
    [deleteCalculation, props.calcID, props.projectID]
  );
  const { ConfirmDialog: ConfirmDialogDelete, openConfirm: openConfirmDelete } =
    useConfirmDialog({
      title: 'Вы уверены?',
      body: 'Расчёт будет удалён и восстановить его будет невозможно',
      handleConfirm: handleConfirmDelete
    });

  useMutationHandlers(deleteCalculationResponse, () => {
    navigate(`/projects/${props.projectID}/calculations`);
    enqueueSnackbar(t('success.calculationDelete'), {
      variant: 'success'
    });
  });

  // * Parameters Calculation * //
  const [calcParameters, setCalcParameters] = useState(false);

  const openCalcParameters = () => {
    setCalcParameters(true);
  };

  const closeCalcParameters = useCallback(() => {
    setCalcParameters(false);
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="stretch">
        <Stack direction="row" flexGrow={1}>
          <Stack
            spacing={5}
            flexGrow={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Tabs value={tabIndex} onChange={handleSelectedTab}>
              {Object.values(INDEX_METHOD_TAB_VALUES).map((value, index) => (
                <Tab key={index} label={value} disabled={index !== 0} />
              ))}
            </Tabs>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1.5} alignItems="center">
          {/* <Button
            style={{
              height: '40px'
            }}
            disabled
            variant="header"
            onClick={openCalcParameters}>
            Параметры расчета
          </Button> */}
          <Button
            onClick={handleOpenMenuCalculation}
            style={{
              height: '40px',
              padding: '8px',
              minWidth: '40px'
            }}>
            <MoreHorizIcon />
          </Button>
        </Stack>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenuCalculation}
        onClose={handleCloseMenuCalculation}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        sx={{
          marginTop: '10px'
        }}>
        <MenuItem
          onClick={() => {
            toggleOpenCopy(true);
            handleCloseMenuCalculation();
          }}>
          Создать копию расчета
        </MenuItem>
        <MenuItem
          onClick={() => {
            openConfirmDelete();
            handleCloseMenuCalculation();
          }}
          disabled={isDisabledDeleteCalculation}>
          Удалить расчет
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleOpenEditCalc();
            handleCloseMenuCalculation();
          }}>
          Редактировать
        </MenuItem>
      </Menu>
      <ParametersDialog
        open={calcParameters}
        onClose={closeCalcParameters}
        isExecution={isExecution}
      />
      <CopyDrawer
        onClose={() => setCopyDrawer(false)}
        open={copyDrawer}
        calculation={props.dataCalculation}
      />
      <DrawerEdit
        onClose={(b) => setEditCalc(b)}
        open={editCalc}
        calculation={props.dataCalculation}
      />
      <ConfirmDialogDelete />
    </Box>
  );
};
