import { FC } from 'react';
import {
  IndexMethodHeader,
  IndexMethodProps,
  IndexMethodWrapper,
  useIndexMethodRest
} from '.';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { IndexMethodCalculation } from 'pages/projects-calculation/index-method-calculation';

/**
 * @description
 * Это РИМ (можно и БИМ сюда бахнуть)
 */
export const IndexMethod: FC<IndexMethodProps> = () => {
  const { calcID, fileID, projectID, mode, calculation, tab, setTab } =
    useIndexMethodRest();

  useBreadcrumbs(
    [
      { title: `Расчеты`, url: `/projects/${projectID}/calculations` },
      {
        title:
          mode === 'viewer' ? 'Предпросмотр' : `${calculation?.title ?? ''}`
      }
    ],
    [calculation, projectID, mode]
  );

  return (
    <>
      {mode === 'editor' && (
        <IndexMethodHeader
          projectID={projectID}
          calcID={calcID}
          dataCalculation={calculation}
          tab={tab}
          setTab={setTab}
        />
      )}
      <IndexMethodWrapper>
        {tab === 'calculation' ? (
          <IndexMethodCalculation
            projectID={projectID}
            calcID={calcID}
            fileID={fileID}
            mode={mode}
          />
        ) : null}
      </IndexMethodWrapper>
    </>
  );
};
