/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  NewValueParams,
  RowClassParams,
  ValueGetterParams,
  ValueSetterParams
} from 'ag-grid-community';
import { isSameDay } from 'date-fns';
import { ru } from 'date-fns/locale';
import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { formatDateToString } from 'utils/formatDate';
import { replaceStringFromServer } from 'utils/replaceStringFromServer';
import useConfirmDialog from '../../../../hooks/useConfirmDialog';
import {
  ExecutionCalculationData,
  GetExecutionCalculationData,
  PartExecution,
  TypeExecutionRow
} from '../../../../types';
import { AgContext } from './Accomplishment';
import Cell, { EditableCell } from './components/Cell';
import CustomTableHeader from './components/CustomTableHeader';
import LevelExecution from './components/LevelExecution';
import { AgGridReact } from 'ag-grid-react';

/**
 *
 * @param data - цифра для модификации (округления)
 * @param min - количество знаков после запятой, если true 2, иначе 0
 * @param replace - если true возвращает цифру в виде строки, с замененной точкой на запятую
 */
export const modifyData = ({
  data,
  min = true,
  replace = false
}: {
  data: number | null;
  min?: boolean;
  replace?: boolean;
}) => {
  if (data === null || data === undefined) return null;
  if (replace) {
    return String(data).replace('.', ',') || null;
  }
  return data.toLocaleString('ru-RU', {
    minimumFractionDigits: min ? 2 : 0,
    maximumFractionDigits: 2
  });
};

/**
 * функция-помощник, генерирует заголовок столбца исходя из дат
 * @param start
 * @param end
 */
export function generateNameHeader(
  start: null | string | Date,
  end: null | string | Date
) {
  let name = '';
  if (!start || !end) return name;
  if (typeof start === 'string' && typeof end === 'string') {
    start = new Date(start);
    end = new Date(end);
  }
  const startMonth = start && formatDateToString(start as Date, 'mm');
  const endMonth = end && formatDateToString(end as Date, 'mm');
  if (startMonth && endMonth) {
    if (startMonth === endMonth) {
      name =
        formatDateToString(start! as Date, 'dd') +
        ' — ' +
        formatDateToString(end! as Date, 'dd MMMM yy', { locale: ru });
    } else {
      name =
        formatDateToString(start! as Date, 'dd.MM.yyyy') +
        ' — ' +
        formatDateToString(end! as Date, 'dd.MM.yyyy');
    }
  }
  return name;
}

function checkError(parts: PartExecution[]) {
  const result: { [key: string]: boolean } = {};
  let total = 0;
  const temp = parts.reduce(
    (acc, act, index) => {
      if (!acc[index]) {
        acc[index] = {
          value: 0,
          type: '',
          total: 0
        };
        acc[index].type = act.type;
        if (acc[index].type === 'total') {
          acc[index].value = 0;
          acc[index].total = act.quantity ?? 0;
          total = acc[index].total;
        } else if (acc[index].type === 'act') {
          if (acc[index - 1]) {
            acc[index].value = acc[index - 1].value + (act.quantity ?? 0);
            acc[index].total = total;
          }
        } else {
          acc[index].value = act.quantity ?? 0;
          acc[index].total = total;
        }
      }

      return acc;
    },
    {} as { [key: string]: { value: number; type: string; total: number } }
  );

  for (const index in temp) {
    const tempKey = Number(index);
    const currentPart = temp[tempKey];

    if (currentPart.type !== 'total') {
      if (currentPart.type !== 'rest') {
        if (currentPart.total > 0) {
          result[tempKey] = currentPart.value > currentPart.total;
        } else {
          result[tempKey] = currentPart.value < currentPart.total;
        }
      } else {
        if (currentPart.total > 0) {
          result[tempKey] = currentPart.value < 0;
        } else {
          result[tempKey] = currentPart.value > 0;
        }
      }
    }
  }
  return result;
}

// function renderError(parts: PartExecution[], part: PartExecution, currentPrice: 'curr' | 'base') {
//   if (currentPrice === 'base') return modifyData({ data: part?.base.workCostInRubles! });
//   const total = parts.find((p) => p?.type === 'total');
//   if (total) {
//     if (part?.type === 'total') {
//       return modifyData({ data: part?.curr.workCostInRubles! });
//     }
//     if (part?.type === 'executed') {
//       if (part.curr.hasError) {
//         return 'Ошибка';
//       } else {
//         return modifyData({ data: part?.curr.workCostInRubles! });
//       }
//     }
//     if (part?.type === 'act') {
//       return modifyData({ data: part?.curr.workCostInRubles! });
//     }
//     if (part?.type === 'rest') {
//       if (part.curr.hasError) {
//         return 'Ошибка';
//       } else {
//         return modifyData({ data: part?.curr.workCostInRubles! });
//       }
//     }
//   }
// }

export const getHeadersParts = (
  parts: PartExecution[] | null,
  total?: PartExecution[] | null,
  isEmpty?: boolean
) => {
  if (!parts || !total) return [];
  const decimalMaxTooltip = 18;
  const decimalMax = 6;

  return (
    parts?.reduce(
      (acc: /*(ColDef<any, any> | ColGroupDef<any>)*/ any[], part, idx) => {
        let headerName: string = generateNameHeader(
          part?.startDate,
          part?.endDate
        );
        switch (part?.type) {
          case 'total':
            headerName = 'Всего';
            break;
          case 'executed':
            headerName = 'Выполнено';
            break;
          case 'rest':
            headerName = 'Остаток';
            break;
        }

        let errors: { [key: number]: boolean } = {};

        function isHaveColorRow(
          type: TypeExecutionRow,
          idPart: number,
          err: typeof errors
        ) {
          if (type === 'file' || type === 'sum') return false;
          return err[idPart] || false;
        }

        // if (!emptyCurr) {
        acc.push({
          field: headerName + idx * 3,
          headerName,
          suppressMovable: true,
          headerClass: (params: any) =>
            part.actID === params?.context?.current?.id
              ? 'header-top-border'
              : '',
          headerGroupComponent: CustomTableHeader,
          // lockPosition: 'right',
          children: [
            {
              // lockPosition: 'right',
              field: 'quantity' + headerName,
              // width: 120,
              cellClass: (params: any) =>
                part.actID === params?.context?.current?.id
                  ? 'header-left-border'
                  : '',
              headerClass: (params: any) =>
                part.actID === params?.context?.current?.id
                  ? 'header-left-border'
                  : '',
              suppressMovable: true,
              // maxWidth: 120,
              minWidth: 120,
              // suppressSpanHeaderHeight: true,
              headerName: 'Кол-во',
              cellEditor: EditableCell,
              valueGetter: (params: ValueGetterParams) => {
                return params?.data?.parts[idx]?.quantity;
              },
              valueSetter: (params: ValueSetterParams) => {
                if (params.context.current) {
                  const copyData = Object.assign({}, params.data);
                  const parts = copyData.parts.map((_: any) =>
                    _.actID === params.context.current.id
                      ? {
                          ..._,
                          quantity:
                            params.newValue
                              ?.replace(/\s/g, '')
                              .replace(',', '.') || null
                        }
                      : _
                  );
                  params.api.applyTransaction({
                    update: [{ ...copyData, parts }]
                  });
                }
              },
              // editable:(props:EditableCallbackParams<any,any>)=>props?.data?.type === 'level' &&
              //   (props?.data?.parts[idx]?.type === 'act' || props?.data?.parts[idx]?.type === 'range') &&
              //   !!props?.context?.current &&
              //   !!props?.data?.parts[idx]?.startDate &&
              //   isSameDay(new Date(props.context.current.startDate), new Date(props?.data?.parts[idx]?.startDate!)),
              onCellValueChanged: (params: NewValueParams<any, any>) => {
                params?.context
                  ?.updateData(
                    params,
                    params.context.current,
                    params.context.calcID
                  )
                  .then(console.log);
              },
              cellRenderer: (
                props: ICellRendererParams<ExecutionCalculationData, any, any>
              ) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const { current } = useContext(AgContext);
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const editable = useMemo(
                  () =>
                    props?.data?.type === 'level' &&
                    (props?.data?.parts[idx]?.type === 'act' ||
                      props?.data?.parts[idx]?.type === 'range') &&
                    !!current &&
                    !!props?.data?.parts[idx]?.startDate &&
                    isSameDay(
                      new Date(current.startDate!),
                      new Date(props?.data?.parts[idx]?.startDate!)
                    ),
                  [props]
                );

                const handleConfirm = (confirm: boolean) => {
                  if (confirm) {
                    props.colDef!.editable = true;
                    props.api.startEditingCell({
                      rowIndex: props.node.rowIndex!,
                      colKey: props.column || ''
                    });
                  }
                };

                // eslint-disable-next-line react-hooks/rules-of-hooks
                const {
                  ConfirmDialog: WarnDialog,
                  openConfirm: openWarnDialog
                } = useConfirmDialog({
                  title: 'Подтвердить внесение изменений?',
                  body: 'Данные будут изменены в закрытом периоде.',
                  handleConfirm
                });

                if (props.data?.parts && props.data.id !== -1) {
                  errors = checkError(props.data.parts);
                }
                const value = props.data?.parts[idx]?.quantity;
                // eslint-disable-next-line react-hooks/rules-of-hooks
                // const renderValue = useMemo(() => {
                //   const currentQuantity = props.data?.parts[idx];
                //   let value: string | null = null;
                //   if (
                //     currentQuantity?.quantity &&
                //     typeof currentQuantity?.quantity === 'number'
                //   ) {
                //     value = currentQuantity
                //       ?.quantity!.toString()
                //       .replace(/\./g, ',');
                //   } else if (
                //     currentQuantity?.quantity &&
                //     typeof currentQuantity?.quantity === 'string'
                //   ) {
                //     value = (currentQuantity?.quantity as string).replace(
                //       /\./g,
                //       ','
                //     );
                //   }
                //   if (props.data?.id === -1) {
                //     value = null;
                //   }
                //   return value;
                // }, [props]);

                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                  if (current && current.status === 'CLOSED') {
                    props.colDef!.editable = false;
                  }
                }, [props, current]);
                return (
                  <Cell
                    click={() => {
                      if (current && current.status === 'CLOSED' && editable) {
                        openWarnDialog(() => {
                          console.log('confirm');
                        });
                      } else {
                        if (editable) {
                          props.colDef!.editable = true;
                          props.api.startEditingCell({
                            rowIndex: props.node.rowIndex!,
                            colKey: props.column?.getColId() || ''
                          });
                        }
                      }
                    }}
                    originData={
                      props.data?.type !== 'file'
                        ? value
                          ? replaceStringFromServer(
                              String(value),
                              decimalMaxTooltip
                            )
                          : null
                        : null
                    }
                    float={'center'}
                    isNum
                    isError={errors[idx] || false}>
                    {props.data?.type !== 'file'
                      ? value
                        ? replaceStringFromServer(String(value), decimalMax)
                        : null
                      : null}
                    {/*{renderValue}*/}
                    <WarnDialog />
                  </Cell>
                );
              }
            },
            {
              field: '',
              columnGroupShow: 'closed',
              // width: 120,
              headerClass: (params: any) =>
                part.actID === params?.context?.current?.id
                  ? 'header-right-border'
                  : '',
              suppressMovable: true,
              // maxWidth: 120,
              minWidth: 120,
              headerGroupComponent,
              // suppressSpanHeaderHeight: true,
              // headerValueGetter: (params: any) => {
              // console.log();
              // return `В ${params?.context?.prices === 'base' ? 'базовых' : 'текущих'} ценах`;
              // },
              children: [
                {
                  // lockPosition: 'right',
                  field: 'workCostInRubles' + headerName,
                  width: 180,
                  suppressMovable: true,
                  maxWidth: 180,
                  headerClass: (params: any) =>
                    part.actID === params?.context?.current?.id
                      ? 'header-right-border'
                      : '',
                  cellClass: (params: any) =>
                    part.actID === params?.context?.current?.id
                      ? 'header-right-border'
                      : '',
                  minWidth: 180,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'Всего стоимость',
                  // headerComponent: (data: IHeaderParams<PartExecution, any>) => <div>{data.displayName}</div>,
                  cellRenderer: ({
                    data,
                    context
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    // const renderValue =
                    //   data?.type !== 'row'
                    //     ? renderError(data?.parts!, data?.parts[idx]!, context?.prices)
                    //     : modifyData({ data: data!.parts[idx]![context!.prices as 'base' | 'curr']?.workCostInRubles! });

                    return (
                      <Cell
                        isHide={
                          (data?.parts[idx]?.quantity === null &&
                            data?.type !== 'file' &&
                            data?.type !== 'sum' &&
                            context?.prices === 'curr') ||
                          // &&
                          // renderValue !== 'Ошибка'
                          data?.type === 'row' ||
                          // renderValue !== 'Ошибка' &&
                          data?.parts[idx]?.[context?.prices as 'base' | 'curr']
                            ?.workCostInRubles === null ||
                          data?.parts[idx]?.[context?.prices as 'base' | 'curr']
                            ?.workCostInRubles === 0
                          // data?.parts[idx]?.type !== 'rest' && data?.parts[idx]?.type !== 'executed'
                        }
                        originData={replaceStringFromServer(
                          String(
                            data!.parts[idx]![
                              context!.prices as 'base' | 'curr'
                            ]?.workCostInRubles!
                          ),
                          decimalMaxTooltip
                        )}
                        isNum
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        float={'right'}>
                        {modifyData({
                          data: data!.parts[idx]![
                            context!.prices as 'base' | 'curr'
                          ]?.workCostInRubles!
                        })}
                        {/*{renderValue}*/}
                      </Cell>
                    );
                  }
                }
              ]
            },
            {
              // lockPosition: 'right',
              field: '',
              width: 120,
              suppressMovable: true,
              maxWidth: 120,
              minWidth: 120,
              columnGroupShow: 'open',
              // suppressSpanHeaderHeight: true,
              headerName: 'В базовых ценах',
              children: [
                {
                  // lockPosition: 'right',
                  field: 'materialsPriceBase',
                  width: 120,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'МТР',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.base?.materialsPrice}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.base?.materialsPrice!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.base?.materialsPrice!
                        })}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'equipmentPriceBase',
                  width: 120,
                  hide: !total[idx].base.equipmentPrice,
                  suppressMovable: false,
                  initialHide: !total.find((_) => _.type === 'total')?.base
                    .equipmentPrice,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'Оборудование',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.base?.equipmentPrice}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.base?.equipmentPrice!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.base?.equipmentPrice!
                        })}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'constructionEffortBase',
                  width: 120,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'ТЗ ОР, чел-ч',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.base?.constructionEffort}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.base?.constructionEffort!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.base?.constructionEffort!
                        })}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'workersSalaryBase',
                  width: 120,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'ФОТ ОР',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.base?.workersSalary}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.base?.workersSalary!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.base?.workersSalary!
                        })}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'mechanicsEffortBase',
                  width: 120,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'ТЗМ, чел-ч',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.base?.mechanicsEffort}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.base?.mechanicsEffort!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.base?.mechanicsEffort!
                        })}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: '',
                  // suppressSpanHeaderHeight: true,
                  suppressMovable: true,
                  headerName: 'МИМ',
                  children: [
                    {
                      // lockPosition: 'right',
                      field: 'mimExploitationBase',
                      width: 120,
                      suppressMovable: true,
                      maxWidth: 120,
                      minWidth: 120,
                      // suppressSpanHeaderHeight: true,
                      headerName: 'Всего',
                      cellRenderer: ({
                        data
                      }: ICellRendererParams<
                        ExecutionCalculationData,
                        any,
                        any
                      >) => {
                        if (data?.parts && data.id !== -1) {
                          errors = checkError(data.parts);
                        }
                        return (
                          <Cell
                            isError={isHaveColorRow(data?.type!, idx, errors)}
                            isHide={!data?.parts[idx]?.base?.mimExploitation}
                            isNum
                            originData={replaceStringFromServer(
                              String(data?.parts[idx]?.base?.mimExploitation!),
                              decimalMaxTooltip
                            )}
                            float={'right'}>
                            {modifyData({
                              data: data?.parts[idx]?.base?.mimExploitation!
                            })}
                          </Cell>
                        );
                      }
                    },
                    {
                      // lockPosition: 'right',
                      field: 'mechanicsSalaryBase',
                      width: 120,
                      suppressMovable: true,
                      maxWidth: 120,
                      minWidth: 120,
                      // suppressSpanHeaderHeight: true,
                      headerName: 'в т.ч. ФОТ мех.',
                      cellRenderer: ({
                        data
                      }: ICellRendererParams<
                        ExecutionCalculationData,
                        any,
                        any
                      >) => {
                        if (data?.parts && data.id !== -1) {
                          errors = checkError(data.parts);
                        }
                        return (
                          <Cell
                            isError={isHaveColorRow(data?.type!, idx, errors)}
                            isHide={!data?.parts[idx]?.base?.mechanicsSalary}
                            isNum
                            originData={replaceStringFromServer(
                              String(data?.parts[idx]?.base?.mechanicsSalary!),
                              decimalMaxTooltip
                            )}
                            float={'right'}>
                            {modifyData({
                              data: data?.parts[idx]?.base?.mechanicsSalary!
                            })}
                          </Cell>
                        );
                      }
                    }
                  ]
                },
                {
                  // lockPosition: 'right',
                  field: 'hpBase',
                  hide: part.type === 'executed' || part.type === 'rest',
                  width: 120,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'НР, %',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    const renderPercent =
                      data?.type !== 'file'
                        ? modifyData({
                            data: data?.parts[idx]?.base.hp!,
                            replace: true
                          })
                        : null;
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.base.hp}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.base.hp!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {renderPercent ? renderPercent + '%' : null}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'overheadsBase',
                  width: 120,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'НР',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.base?.overheads}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.base?.overheads!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.base?.overheads!
                        })}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'spBase',
                  hide: part.type === 'executed' || part.type === 'rest',
                  width: 120,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'СП, %',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    const renderPercent =
                      data?.type !== 'file'
                        ? modifyData({
                            data: data?.parts[idx]?.base.sp!,
                            replace: true
                          })
                        : null;
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.base.sp}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.base.sp!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {renderPercent ? renderPercent + '%' : null}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'estimatedProfitBase',
                  width: 120,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'СП',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.base?.estimatedProfit}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.base?.estimatedProfit!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.base?.estimatedProfit!
                        })}
                      </Cell>
                    );
                  }
                },
                ...(!total?.[idx]?.base?.dynamicRows?.length
                  ? []
                  : [
                      {
                        // lockPosition: 'right',
                        field: 'totalWorkCostBase',
                        width: 120,
                        suppressMovable: true,
                        maxWidth: 120,
                        minWidth: 120,
                        // suppressSpanHeaderHeight: true,
                        headerName: 'Итого',
                        cellRenderer: ({
                          data
                        }: ICellRendererParams<
                          ExecutionCalculationData,
                          any,
                          any
                        >) => {
                          if (data?.parts && data.id !== -1) {
                            errors = checkError(data.parts);
                          }
                          return (
                            <Cell
                              isHide={!data?.parts[idx]?.base?.totalWorkCost}
                              isError={isHaveColorRow(data?.type!, idx, errors)}
                              isNum
                              originData={replaceStringFromServer(
                                String(data?.parts[idx]?.base?.totalWorkCost!),
                                decimalMaxTooltip
                              )}
                              float={'right'}>
                              {modifyData({
                                data: data?.parts[idx]?.base?.totalWorkCost!
                              })}
                            </Cell>
                          );
                        }
                      }
                    ]),
                ...(!part?.base?.dynamicRows?.length
                  ? []
                  : part.base.dynamicRows.map((dyn, idxDyn) => {
                      return {
                        // lockPosition: 'right',
                        field: dyn.title + 'Base',
                        width: 120,
                        suppressMovable: true,
                        maxWidth: 120,
                        minWidth: 120,
                        // suppressSpanHeaderHeight: true,
                        headerName: dyn.title,
                        cellRenderer: ({
                          data
                        }: ICellRendererParams<
                          ExecutionCalculationData,
                          any,
                          any
                        >) => {
                          if (data?.parts && data.id !== -1) {
                            errors = checkError(data.parts);
                          }
                          return (
                            <Cell
                              isHide={
                                !data?.parts[idx]?.base?.dynamicRows?.[idxDyn]
                                  ?.value
                              }
                              isError={isHaveColorRow(data?.type!, idx, errors)}
                              isNum
                              originData={replaceStringFromServer(
                                String(
                                  data?.parts[idx]?.base?.dynamicRows?.[idxDyn]
                                    ?.value!
                                ),
                                decimalMaxTooltip
                              )}
                              float={'right'}>
                              {modifyData({
                                data: data?.parts[idx]?.base?.dynamicRows?.[
                                  idxDyn
                                ]?.value!
                              })}
                            </Cell>
                          );
                        }
                      };
                    })),
                {
                  // lockPosition: 'right',
                  field: 'workCostInRubles' + headerName,
                  width: 180,
                  suppressMovable: true,
                  maxWidth: 180,
                  minWidth: 180,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'Всего стоимость',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    // const renderRubles = data?.parts[idx]?.base?.workCostInRubles;
                    // const renderRubles = data?.parts[idx]?.base?.dynamicRows?.length
                    //   ? data?.parts[idx]?.base?.workCostInRubles
                    //   : data?.parts[idx]?.base?.totalWorkCost;
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    // const renderValue =
                    //   data?.type !== 'row' && data?.type !== 'sum'
                    //     ? renderError(data?.parts!, data?.parts[idx]!, 'base')
                    //     : modifyData({ data: data?.parts[idx]!.base.workCostInRubles! });

                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={
                          (data?.parts[idx]?.quantity === null &&
                            data?.type !== 'sum' &&
                            data?.type !== 'file') ||
                          // &&
                          // renderValue !== 'Ошибка'
                          data?.type === 'row' ||
                          // renderValue !== 'Ошибка' &&
                          data?.parts[idx]?.base?.workCostInRubles === null ||
                          data?.parts[idx]?.base?.workCostInRubles === 0
                        }
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]!.base.workCostInRubles!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {/*{renderValue}*/}
                        {modifyData({
                          data: data?.parts[idx]!.base.workCostInRubles!
                        })}
                      </Cell>
                    );
                  }
                }
              ]
            },
            {
              // lockPosition: 'right',
              field: '',
              width: 120,
              hide: isEmpty,
              suppressMovable: true,
              maxWidth: 120,
              minWidth: 120,
              columnGroupShow: 'open',
              headerClass: (params: any) =>
                part.actID === params?.context?.current?.id
                  ? 'header-right-border'
                  : '',
              // suppressSpanHeaderHeight: true,
              headerName: 'В текущих ценах',
              children: [
                {
                  // lockPosition: 'right',
                  field: 'materialsPriceCurr',
                  width: 120,
                  hide: isEmpty,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'МТР',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.curr.materialsPrice}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.curr.materialsPrice!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.curr.materialsPrice!
                        })}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'equipmentPriceCurr',
                  width: 120,
                  hide: isEmpty || !total[idx].curr.equipmentPrice,
                  suppressMovable: true,
                  initialHide: !total.find((_) => _.type === 'total')?.curr
                    .equipmentPrice,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'Оборудование',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.curr.equipmentPrice}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.curr.equipmentPrice!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.curr.equipmentPrice!
                        })}
                      </Cell>
                    );
                  }
                },

                {
                  // lockPosition: 'right',
                  field: 'constructionEffortCurr',
                  width: 120,
                  hide: isEmpty,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'ТЗ ОР, чел-ч',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.curr.constructionEffort}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.curr?.constructionEffort!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.curr?.constructionEffort!
                        })}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'workersSalaryCurr',
                  width: 120,
                  hide: isEmpty,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'ФОТ ОР',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.curr.workersSalary}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.curr.workersSalary!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.curr.workersSalary!
                        })}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'mechanicsEffortCurr',
                  width: 120,
                  hide: isEmpty,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'ТЗМ, чел-ч',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.curr.mechanicsEffort}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.curr.mechanicsEffort!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.curr.mechanicsEffort!
                        })}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: '',
                  // suppressSpanHeaderHeight: true,
                  suppressMovable: true,
                  hide: isEmpty,
                  headerName: 'МИМ',
                  children: [
                    {
                      // lockPosition: 'right',
                      field: 'mimExploitationCurr',
                      width: 120,
                      hide: isEmpty,
                      suppressMovable: true,
                      maxWidth: 120,
                      minWidth: 120,
                      // suppressSpanHeaderHeight: true,
                      headerName: 'Всего',
                      cellRenderer: ({
                        data
                      }: ICellRendererParams<
                        ExecutionCalculationData,
                        any,
                        any
                      >) => {
                        if (data?.parts && data.id !== -1) {
                          errors = checkError(data.parts);
                        }
                        return (
                          <Cell
                            isError={isHaveColorRow(data?.type!, idx, errors)}
                            isHide={!data?.parts[idx]?.curr.mimExploitation}
                            isNum
                            originData={replaceStringFromServer(
                              String(data?.parts[idx]?.curr.mimExploitation!),
                              decimalMaxTooltip
                            )}
                            float={'right'}>
                            {modifyData({
                              data: data?.parts[idx]?.curr.mimExploitation!
                            })}
                          </Cell>
                        );
                      }
                    },
                    {
                      // lockPosition: 'right',
                      field: 'mechanicsSalaryCurr',
                      width: 120,
                      hide: isEmpty,
                      suppressMovable: true,
                      maxWidth: 120,
                      minWidth: 120,
                      // suppressSpanHeaderHeight: true,
                      headerName: 'в т.ч. ФОТ мех.',
                      cellRenderer: ({
                        data
                      }: ICellRendererParams<
                        ExecutionCalculationData,
                        any,
                        any
                      >) => {
                        if (data?.parts && data.id !== -1) {
                          errors = checkError(data.parts);
                        }
                        return (
                          <Cell
                            isError={isHaveColorRow(data?.type!, idx, errors)}
                            isHide={!data?.parts[idx]?.curr.mechanicsSalary}
                            isNum
                            originData={replaceStringFromServer(
                              String(data?.parts[idx]?.curr.mechanicsSalary!),
                              decimalMaxTooltip
                            )}
                            float={'right'}>
                            {modifyData({
                              data: data?.parts[idx]?.curr.mechanicsSalary!
                            })}
                          </Cell>
                        );
                      }
                    }
                  ]
                },
                {
                  // lockPosition: 'right',
                  field: 'hpCurr',
                  width: 120,
                  hide:
                    isEmpty || part.type === 'executed' || part.type === 'rest',
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'НР, %',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    const renderPercent =
                      data?.type !== 'file'
                        ? modifyData({
                            data: data?.parts[idx]?.curr.hp!,
                            replace: true
                          })
                        : null;
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.curr.hp}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.curr.hp!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {renderPercent ? renderPercent + '%' : null}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'overheadsCurr',
                  width: 120,
                  hide: isEmpty,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'НР',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.curr.overheads}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.curr.overheads!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.curr.overheads!
                        })}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'spCurr',
                  hide:
                    isEmpty || part.type === 'executed' || part.type === 'rest',
                  width: 120,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'СП, %',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    const renderPercent =
                      data?.type !== 'file'
                        ? modifyData({
                            data: data?.parts[idx]?.curr.sp!,
                            replace: true
                          })
                        : null;
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.curr.sp}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.curr.sp!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {renderPercent ? renderPercent + '%' : null}
                      </Cell>
                    );
                  }
                },
                {
                  // lockPosition: 'right',
                  field: 'estimatedProfitCurr',
                  width: 120,
                  hide: isEmpty,
                  suppressMovable: true,
                  maxWidth: 120,
                  minWidth: 120,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'СП',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    return (
                      <Cell
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isHide={!data?.parts[idx]?.curr.estimatedProfit}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]?.curr.estimatedProfit!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {modifyData({
                          data: data?.parts[idx]?.curr.estimatedProfit!
                        })}
                      </Cell>
                    );
                  }
                },
                ...(!total?.[idx]?.curr?.dynamicRows?.length
                  ? []
                  : [
                      {
                        // lockPosition: 'right',
                        field: 'totalWorkCostCurr',
                        width: 120,
                        hide: isEmpty,
                        suppressMovable: true,
                        maxWidth: 120,
                        minWidth: 120,
                        // suppressSpanHeaderHeight: true,
                        headerName: 'Итого',
                        cellRenderer: ({
                          data
                        }: ICellRendererParams<
                          ExecutionCalculationData,
                          any,
                          any
                        >) => {
                          if (data?.parts && data.id !== -1) {
                            errors = checkError(data.parts);
                          }
                          return (
                            <Cell
                              isHide={!data?.parts[idx]?.curr.totalWorkCost}
                              isError={isHaveColorRow(data?.type!, idx, errors)}
                              isNum
                              originData={replaceStringFromServer(
                                String(data?.parts[idx]?.curr.totalWorkCost!),
                                decimalMaxTooltip
                              )}
                              float={'right'}>
                              {modifyData({
                                data: data?.parts[idx]?.curr.totalWorkCost!
                              })}
                            </Cell>
                          );
                        }
                      }
                    ]),
                ...(!part?.curr.dynamicRows.length
                  ? []
                  : part.curr.dynamicRows.map((dyn, idxDyn) => {
                      return {
                        // lockPosition: 'right',
                        field: dyn.title + 'Curr',
                        width: 120,
                        hide: isEmpty,
                        suppressMovable: true,
                        maxWidth: 120,
                        minWidth: 120,
                        // suppressSpanHeaderHeight: true,
                        headerName: dyn.title,
                        cellRenderer: ({
                          data
                        }: ICellRendererParams<
                          ExecutionCalculationData,
                          any,
                          any
                        >) => {
                          if (data?.parts && data.id !== -1) {
                            errors = checkError(data.parts);
                          }
                          return (
                            <Cell
                              isHide={
                                !data?.parts[idx].curr?.dynamicRows?.[idxDyn]
                                  ?.value
                              }
                              isError={isHaveColorRow(data?.type!, idx, errors)}
                              isNum
                              originData={replaceStringFromServer(
                                String(
                                  data?.parts[idx].curr?.dynamicRows?.[idxDyn]
                                    ?.value!
                                ),
                                decimalMaxTooltip
                              )}
                              float={'right'}>
                              {modifyData({
                                data: data?.parts[idx].curr?.dynamicRows?.[
                                  idxDyn
                                ]?.value!
                              })}
                            </Cell>
                          );
                        }
                      };
                    })),
                {
                  // lockPosition: 'right',
                  field: 'workCostInRubles' + headerName,
                  width: 180,
                  hide: isEmpty,
                  suppressMovable: true,
                  maxWidth: 180,
                  headerClass: (params: any) =>
                    part.actID === params?.context?.current?.id
                      ? 'header-right-border'
                      : '',
                  cellClass: (params: any) =>
                    part.actID === params?.context?.current?.id
                      ? 'header-right-border'
                      : '',
                  minWidth: 180,
                  // suppressSpanHeaderHeight: true,
                  headerName: 'Всего стоимость',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    ExecutionCalculationData,
                    any,
                    any
                  >) => {
                    // const checkCurrentPrice = checkPartsAct(data?.parts);
                    // const renderRubles = data?.parts[idx]?.curr.workCostInRubles;
                    // const renderRubles = data?.parts[idx]?.curr.dynamicRows.length
                    //   ? data?.parts[idx]?.curr.workCostInRubles
                    //   : data?.parts[idx]?.curr.totalWorkCost;
                    // const renderValue =
                    //   !checkCurrentPrice &&
                    //   data?.type !== 'row' &&
                    //   currentPrice === 'curr' &&
                    //   (data?.parts[idx]?.type === 'rest' || data?.parts[idx]?.type === 'executed')
                    //     ? 'Ошибка'
                    //     : modifyData({ data: renderRubles! });
                    if (data?.parts && data.id !== -1) {
                      errors = checkError(data.parts);
                    }
                    // const renderValue =
                    //   data?.type !== 'row' && data?.type !== 'sum'
                    //     ? renderError(data?.parts!, data?.parts[idx]!, 'curr')
                    //     : modifyData({ data: data?.parts[idx]!.curr.workCostInRubles! });

                    return (
                      <Cell
                        isHide={
                          (data?.parts[idx]?.quantity === null &&
                            data?.type !== 'sum' &&
                            data?.type !== 'file') ||
                          // &&
                          // renderValue !== 'Ошибка'
                          data?.type === 'row' ||
                          // renderValue !== 'Ошибка' &&
                          data?.parts[idx]?.curr?.workCostInRubles === null ||
                          data?.parts[idx]?.curr?.workCostInRubles === 0

                          // data?.parts[idx]?.type !== 'rest' &&
                          // data?.parts[idx]?.type !== 'executed'
                        }
                        isError={isHaveColorRow(data?.type!, idx, errors)}
                        isNum
                        originData={replaceStringFromServer(
                          String(data?.parts[idx]!.curr.workCostInRubles!),
                          decimalMaxTooltip
                        )}
                        float={'right'}>
                        {/*{renderValue}*/}
                        {modifyData({
                          data: data?.parts[idx]!.curr.workCostInRubles!
                        })}
                      </Cell>
                    );
                  }
                }
              ]
            }
          ]
        });
        return acc;
      },
      []
    ) || []
  );
};
const headerGroupComponent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { prices } = useContext(AgContext);

  return <span>В {prices === 'base' ? 'базовых' : 'текущих'} ценах</span>;
};
export const getRowClass = (
  params: RowClassParams<ExecutionCalculationData>
) => {
  const colors = {
    inactive: '#B8B8B8',
    deleted: '#B8B8B8',
    dont_belong: '#FF0101'
  };
  const styles: React.CSSProperties = {};

  const classCell = [];

  if (params.node.data?.type === 'file') {
    classCell.push('level-1');
  } else if (params.node.data?.type === 'level') {
    classCell.push('rate');
  } else if (params.node.data?.type === 'sum') {
    classCell.push('rate-position');
  } else {
    classCell.push('cell-bg-def');
  }
  if (params.node.data?.subtype) {
    classCell.push(classCell[0] + '-' + params.node.data.subtype);
    styles.color = colors[params.node.data.subtype];
  }
  return classCell;
};
export const useTable = (
  data: GetExecutionCalculationData | undefined,
  Ref: MutableRefObject<AgGridReact<ExecutionCalculationData> | null>,
  emptyCurr: boolean,
  isFetching: boolean | undefined
) => {
  const columnsRef = useRef<
    | (
        | ColDef<ExecutionCalculationData, any>
        | ColGroupDef<ExecutionCalculationData>
      )[]
    | undefined
  >();
  useEffect(() => {
    if (data?.tree && data?.total) {
      columnsRef.current = [
        {
          field: 'level',
          pinned: 'left',
          lockPinned: true,
          suppressMovable: true,
          width: 124,
          maxWidth: 124,
          minWidth: 124,
          cellStyle: {
            padding: '0 10px'
          },
          colSpan: (params: any) => {
            return params.data?.id === -1 ? 8 : 0;
          },

          headerName: 'Ур.',

          cellRenderer: (
            params: ICellRendererParams<ExecutionCalculationData, any, any>
          ) => {
            return <LevelExecution {...params} />;
          }
        },
        {
          field: 'number',
          pinned: 'left',
          lockPinned: true,
          width: 60,
          suppressMovable: false,
          maxWidth: 60,
          minWidth: 60,
          headerName: '№ п\\п',

          cellRenderer: ({
            data
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell float={'center'}>{data?.number}</Cell>;
          }
        },
        {
          field: 'lsr',
          pinned: 'left',
          lockPinned: true,
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerName: 'Шифр ЛСР',
          cellRenderer: ({
            data
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell>{data?.lsr}</Cell>;
          }
        },
        {
          field: 'chapter',
          pinned: 'left',
          lockPinned: true,
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerName: 'Раздел',
          cellRenderer: ({
            data
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell>{data?.chapter}</Cell>;
          }
        },
        {
          field: 'header',
          pinned: 'left',
          lockPinned: true,
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerName: 'Заголовок',
          cellRenderer: ({
            data
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell>{data?.header}</Cell>;
          }
        },
        {
          field: 'code',
          pinned: 'left',
          lockPinned: true,
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerName: 'Код расценки',
          cellRenderer: ({
            data
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell>{data?.code}</Cell>;
          }
        },
        {
          field: 'title',
          pinned: 'left',
          lockPinned: true,
          width: 310,
          suppressMovable: false,
          maxWidth: 500,
          minWidth: 310,
          headerName: 'Наименование',
          cellRenderer: ({
            data
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell>{data?.title}</Cell>;
          }
        },

        {
          field: 'unit',
          pinned: 'left',
          lockPinned: true,
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerName: 'Ед. изм.',
          cellRenderer: ({
            data
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell float={'center'}>{data?.unit}</Cell>;
          }
        },
        ...getHeadersParts(
          data?.tree ? data?.tree?.[0]?.parts || [] : [],
          data?.total ? data?.total?.[0]?.parts || [] : [],
          emptyCurr
        )
      ];
      Ref.current?.api?.refreshCells({ force: true, suppressFlash: true });
      Ref.current?.api?.refreshHeader();
    }
  }, [data?.tree, data?.total, isFetching]);
  return columnsRef;
};
