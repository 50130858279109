import styled from 'styled-components';

export const IndexMethodWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: auto;
  display: grid;
  height: 100%;
`;
